import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import Breadcrumb from "./Breadcrumb";

export default function Partners() {
    var BreadCrumbTitle = "Companies and Partners";
    document.title = BreadCrumbTitle;
    return (
        <>
            <Header/>
            <main>
                <Breadcrumb PageTitle={BreadCrumbTitle}/>

                <section className="blog-details-area pt-120 pb-60">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="blog-details-wrap">
                                    <div className="standard-blog-content blog-details-content">
                                        <p>
                                            Matched companies included, but not limited to: Aetna, Accuquote, AHCP,
                                            AHIA, AIP, Allegiant Group,Alpine Digital Group, Allied, American Benefit
                                            Services , American Continental Insurance, American Family Insurance,
                                            American Health Brokerage, American Health Underwriters, American Republic,
                                            American Republic Insurance, Amerigroup, Amerigroup, Amerilife, Amerilife
                                            Marketing Group, Ameriquote, AON, Art Jetter & Co, Assurant, Assured Life,
                                            AvMed Inc., AXA Advisors, Bankers Life Insurance, Benefit Mall, Cambia,
                                            CareMore, CareSource, Cars Insurance, CDPHP, Centene, Cege Media, Cigna
                                            Health Group, Combined, Community Care, Complete Senior Benefits,
                                            ConnectiCare, Continental Life, Country Insurance, Coventry Health Care,
                                            Coverage One Insurance, DBA “Connect Insurance Brands”, E-Telequote,
                                            eHealth, Elderplan, EmblemHealth,Ensurem, Fallon Health, Family Life,
                                            Fidelis Care, First Financial, Forefront Health Insurance Solutions,
                                            Forethought, Geisinger Insurance, Genworth, Gerber Life, GHI, GMAC, Golden
                                            Rule, GoMedigap.com, Government Personnel Mutual Life, Guarantee Trust Life,
                                            GuideStar Marketing Group LLC, Guide2Insure, HAP Health Alliance, Harvard
                                            Pilgrim Health Care, Health Care Service Corp., Health Choice
                                            One,Healthinsurance.com, Health Insurance for Everyone, Health Markets,
                                            Health Net, Health Now New York Inc., Health Partners of Philadelphia
                                            ,HealthPocket Inc,Health Plan Intermediaries Holdings, LLC, Health Refrom
                                            Team , HealthPartners, HealthPlanOne, HealthPlus of Michigan, HealthSpring,
                                            Helios Energy Partners, Highmark, Hola Doctor, Humana, Errands Services Pvt.
                                            Ltd, iHealth Brokers, InsideResponse, Insphere, IntegratedBenefits, Inter
                                            Valley Health Plan, JLS, Kaiser Permanenente, KBM Group: Health Services,
                                            Keystone, LifeLine Direct Insurance Services , LifeWise Health Plan of
                                            Oregon, Longevity Alliance, Legal & General America, Loyal American (GIGNA
                                            Supplement), MedicareSolutions, MediGap Direct, MercyCare, MetLife, Molina,
                                            Mutual of Omaha, MVP Health Care, MyExclusiveQuotes.com, National Family
                                            Assurance Group, Network Health, New Era, Noridian Mutual Insurance Company,
                                            Optimum HealthCare, Physicians Mutual, Physicians United Plan, Potesma
                                            Marketing Group, Precise Leads, Preferred Care Partners, Presbyterian Health
                                            Plan, Priority Health, Priority Health, Providence Health Plan, Puritan
                                            Health,QuoteManage LLC, QuoteWhiz, Regal, Regence, SCAN Health Plan, SCMS,
                                            Scott and White, Selectmypolicy.com, SelectQuote, Senior Health Direct,
                                            Senior Market Partners, Senior Market Sales, Sentinel,Smart Match Insurance
                                            Agency, SolidQuote LLC Spring Venture Group, Stability Life, Standard Life,
                                            State Mutual, Sterling, SummaCare, Superior Insurance, TexanPlus,Total
                                            Insurance Brokers Inc, Touchstone, Transamerica, TZ Insurance Solutions,
                                            Tufts, Ucare, Unicare, United American, United Health Care, United Medicare
                                            Advisors, United of Omaha, Universal American, UPMC Health Plan, Vasa North
                                            Atlantic, Velapoint , VelaPoint Insurance, Viva Health, Wellcare, WellPoint,
                                            Windsor, North Star Insurance Advisors, Premier Producers Group and Senior
                                            Benefits.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            <Footer/>
        </>
    )
}
