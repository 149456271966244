import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import Breadcrumb from "./Breadcrumb";

export default function TermsConditions() {
    var BreadCrumbTitle = "Affordable Connectivity Program Terms & Conditions";
    document.title = BreadCrumbTitle;
    return (
        <>
            <Header/>
            <main>
                <Breadcrumb PageTitle={BreadCrumbTitle}/>

                <section className="blog-details-area pt-120 pb-60">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="blog-details-wrap">
                                    <div className="standard-blog-content blog-details-content">
                                        <h2 className="title">Effective Date: September 21, 2022</h2>
                                        <p>
                                            Please read these FreeWirelessOptions, Inc (“FreeWirelessOptions”)
                                            Affordable Connectivity Program
                                            (“ACP”) Terms and Conditions of Service (“Agreement”) carefully. This
                                            Agreement is legally binding between you and FreeWirelessOptions and becomes
                                            effective
                                            when you enroll in FreeWirelessOptions’s ACP service, use the
                                            FreeWirelessOptions ACP service, or
                                            make a change to your account. These terms and conditions contain important
                                            information about your legal rights and require that certain disputes be
                                            resolved through Arbitration instead of a court trial. FreeWirelessOptions
                                            reserves the
                                            right to change or modify this Agreement at any time and at its sole
                                            discretion. Any changes or modifications to this Agreement will be binding
                                            upon you, once posted on the FreeWirelessOptions website at
                                            www.FreeWirelessOptions.com. You should
                                            check the FreeWirelessOptions website regularly for updates to this
                                            Agreement.
                                        </p>
                                        <p>
                                            <strong>Nature of Service: </strong> The ACP is a Federal Communications
                                            Commission (“FCC”)
                                            benefit program designed to help make broadband more affordable for eligible
                                            low-income households. The ACP will provide a discount of up to $30 per
                                            month towards broadband service or up to $75 per month for households on
                                            Tribal lands, as defined by FCC rules. Eligible households can also receive
                                            a one-time discount of up to $100 to purchase a laptop, desktop computer, or
                                            tablet from participating providers if they contribute more than $10 and
                                            less than $50 toward the purchase price. The ACP is limited to one monthly
                                            service discount and one device discount per household. ACP benefits may be
                                            obtained from any participating provider and the monthly service benefit may
                                            be transferred to another provider subject to certain regulatory
                                            restrictions. The ACP benefit is subject to consumer eligibility, provider
                                            participation, product availability, and approval by the FCC and its ACP
                                            administrator, the Universal Service Administrative Company (“USAC”). This
                                            ACP discount benefit is provided each month the customer maintains ACP
                                            services and eligibility.
                                        </p>

                                        <p>
                                            By applying or subscribing to FreeWirelessOptions services supported by the
                                            ACP, you
                                            authorize FreeWirelessOptions and its contracted partners, for the purpose
                                            of applying
                                            for, determining eligibility, enrolling in and seeking reimbursement of ACP
                                            service and device benefits, to collect, use, share and retain your personal
                                            information, including, but not limited to, information required for the
                                            purpose of establishing eligibility for and enrolling in the program, full
                                            name, full residential address, date of birth, last four digits of social
                                            security number, telephone number, eligibility criteria and status, the date
                                            on which the ACP service discount was initiated and (if applicable)
                                            terminated, ACP connected device distribution date/type/make and
                                            model/status, usage status and other compliance requirements, the amount of
                                            support being sought for the service and/or device, and information
                                            necessary to establish identity and verifiable address. The information
                                            collected may be shared with USAC to ensure proper administration of the ACP
                                            service and/or connected device benefits. Failure to provide this FCC
                                            mandated consent will result in your being denied the ACP service and/or the
                                            connected device benefits. You also authorize FreeWirelessOptions and its
                                            contracted
                                            partners to contact you to validate your eligibility for or desire to
                                            participate in FreeWirelessOptions’s ACP offers. For more information see
                                            our General
                                            Terms and Conditions and our Privacy Policy at www.FreeWirelessOptions.com.
                                        </p>
                                        <p>
                                            <strong>Eligibility: </strong> To qualify for enrollment in the ACP, a
                                            person applying for ACP service, or “applicant,” must meet specific
                                            eligibility requirements and have a service address in a location where
                                            FreeWirelessOptions is authorized to offer ACP service as an approved
                                            Broadband Service Provider.
                                        </p>
                                        <p>
                                            <strong>Application Process: </strong>
                                            FreeWirelessOptions subscribers who participate in the Lifeline
                                            program may OPT-IN to the ACP which is a separate program. New applicants
                                            must complete an ACP application and certification form and may need to
                                            provide supporting documentation verifying they meet the eligibility
                                            requirements to participate in the ACP.
                                            <br/>
                                            New ACP customers who are enrolled in FreeWirelessOptions ACP services will
                                            receive one
                                            (1) SIM Card enabling the user to access the FreeWirelessOptions network
                                            with a
                                            compatible GSM device. Existing Lifeline customers who opt-in to enroll in
                                            FreeWirelessOptions ACP services may be able to use their current SIM card.
                                            ACP
                                            Participants may also qualify for a one-time discount of up to $100 on a
                                            connected device. If the participant has not yet received their one-time
                                            discount, they may receive one (1) broadband enabled Connected Device
                                            provided by FreeWirelessOptions in accordance with its Device Policy and the
                                            ACP
                                            guidelines.
                                        </p>
                                        <p>
                                            <strong>One ACP Discount Per Economic Unit Rule: </strong>
                                            ACP support is limited to one
                                            economic unit per household and consists of broadband service. An economic
                                            unit is defined, for purposes of the ACP, as any individual or group of
                                            individuals who live together at the same address and share income and
                                            expenses. An economic unit is not permitted to receive ACP benefits from
                                            multiple providers. Violation of the one benefit per economic unit rule
                                            constitutes a violation of the federal rules and will result in
                                            de-enrollment from the ACP and potentially prosecution by the United States
                                            government.
                                            <br/>
                                            You consent to have your personal identification information, including
                                            name, telephone number and address shared with USAC and/or its agents to
                                            confirm that neither you nor your household receive more than one ACP
                                            benefit.
                                            <br/>
                                            ACP Benefit Transfer Consent. If you or any member of your family unit
                                            receives ACP Assistance from any other telephone company or broadband
                                            internet provider, you consent to have your benefit transferred to
                                            FreeWirelessOptions when you apply for and enroll in ACP services with
                                            FreeWirelessOptions. The effect of an ACP benefit transfer is that your ACP
                                            benefit will be applied to FreeWirelessOptions’s ACP service and will no
                                            longer be applied to service retained from your former ACP service provider.
                                            You may be subject to your former ACP provider’s undiscounted rates as a
                                            result of the transfer if you elect to maintain service from that provider.
                                            ACP rules limit you to one ACP benefit transfer transaction per service
                                            month, with limited exceptions for situations where a subscriber seeks to
                                            reverse an unauthorized benefit transfer or is unable to receive service
                                            from a specific provider.
                                        </p>
                                        <p>
                                            <strong>ACP and Lifeline: </strong> Customers may have one ACP and one
                                            Lifeline benefit per household. These discount benefits may be combined and
                                            applied to the same service or they may be applied to two separate services.
                                            The ACP benefit is separate from the Lifeline benefit. You are not required
                                            to opt-in to the ACP, and you can continue to receive your Lifeline service
                                            without enrolling in the ACP. You may choose to take ACP benefits from a
                                            service provider other than your existing Lifeline provider.
                                        </p>
                                        <p>
                                            <strong>ACP Benefit Availability: </strong> The ACP benefit is subject to
                                            funding availability from the FCC. Should the FCC announce that funding is
                                            no longer available, FreeWirelessOptions will attempt to provide you with a
                                            30-day notice that it will discontinue its ACP-supported service. At the
                                            conclusion of the ACP, customers may choose to pay the applicable
                                            undiscounted rate plus applicable fees and taxes for one of our service
                                            plans available at that time. FreeWirelessOptions will not opt the customer
                                            into AutoPay without the customer’s permission, and the customer must have a
                                            credit card on file to enter the AutoPay program.
                                        </p>
                                        <p>
                                            <strong>Consent to Disclosure of Information: </strong> By completing the
                                            FreeWirelessOptions ACP application, you consent to the release of your
                                            information (including financial information) to our designated agent for
                                            the administration of your service with FreeWirelessOptions. This consent
                                            survives the termination of this Agreement. You further authorize
                                            FreeWirelessOptions to discuss with or access information from state or
                                            federal agency representatives concerning your eligibility for and
                                            participation in the ACP. FreeWirelessOptions reserves the right to review
                                            your eligibility status at any time and require you to provide
                                            FreeWirelessOptions with written documentation of either your household’s
                                            income or your participation in a qualifying federal program or state
                                            program.
                                        </p>




                                        


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            <Footer/>
        </>
    )
}
