export default function Header() {
    return (
        <>
            <button className="scroll-top scroll-to-target" data-target="html">
                <i className="fas fa-angle-up"></i>
            </button>
            <header className="header-style-two">
                <div className="header-two-logo">
                    <div className="container custom-container">
                        <div className="path-logo">
                            <a href="/"><img src="template/img/logo/fw_logo.png" alt=""/></a>
                        </div>
                    </div>
                </div>
                <div className="header-top-wrap">
                    <div className="container custom-container">
                        <div className="row">
                            <div className="col-xl-8 col-md-7 col-sm-7">
                                <div className="header-top-left">
                                    <ul>
                                        <li className="d-none d-xxl-flex"><i className="flaticon-location"></i> 14/A,
                                            Queen Street City, New York, US
                                        </li>
                                        <li className="d-none d-lg-flex"><i className="flaticon-email"></i>
                                            <a href="mailto:info@freewirelessoptions.com">info@freewirelessoptions.com</a>
                                        </li>
                                        <li><i className="flaticon-clock"></i> Opening Time : 10: AM - 10 PM</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-5 col-sm-5">
                                <div className="header-top-right hide d-none">
                                    <ul>
                                        <li className="header-user-info">
                                            <i className="flaticon-businessman"></i>
                                            <a href="/login-register">Login / Register</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="header-top-fixed"></div>
                <div id="sticky-header" className="menu-area">
                    <div className="container custom-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="mobile-nav-toggler"><i className="fas fa-bars"></i></div>
                                <div className="menu-wrap">
                                    <nav className="menu-nav">
                                        <div className="logo">
                                            <a href="/"><img src="template/img/logo/logo.png" alt=""/></a>
                                        </div>
                                        <div className="navbar-wrap main-menu "
                                             style={{height: "96px", lineHeight: "96px"}}>
                                            <a href="tel:8335691494">Call US: 833-569-1494</a>
                                        </div>
                                    </nav>
                                </div>

                                <div className="mobile-menu">
                                    <nav className="menu-box">
                                        <div className="close-btn"><i className="fal fa-times"></i></div>
                                        <div className="nav-logo"><a href="index.html">
                                            <img src="template/img/logo/logo.png" alt="" title=""/></a>
                                        </div>
                                        <div className="menu-outer">
                                            xxxxxxxxxx
                                        </div>
                                        <div className="social-links">
                                            <ul className="clearfix">
                                                <li><a href="#"><span className="fab fa-facebook-f"></span></a></li>
                                                <li><a href="#"><span className="fab fa-twitter"></span></a></li>
                                                <li><a href="#"><span className="fab fa-pinterest-p"></span></a></li>
                                                <li><a href="#"><span className="fab fa-instagram"></span></a></li>
                                                <li><a href="#"><span className="fab fa-youtube"></span></a></li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                                <div className="menu-backdrop"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
