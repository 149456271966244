export default function Footer() {
    return (
        <>
            <footer>
                <div className="copyright-wrap">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-4">

                            </div>
                            <div className="col-md-8">
                                <div className="copyright-text text-center text-md-end">
                                    <p>Copyrighted @ 2023 - All Right Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
