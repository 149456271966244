import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import Breadcrumb from "./Breadcrumb";

export default function NoPage() {
    var BreadCrumbTitle = "404 Error Page";
    document.title = BreadCrumbTitle;

    return (
        <>
            <Header/>
            <main>
                <Breadcrumb PageTitle={BreadCrumbTitle}/>
                <section className="error-area pt-120 pb-50">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                                <div className="error-img text-center mb-40">
                                    <img src="template/img/images/404.png" alt=""/>
                                </div>
                                <div className="error-content text-center">
                                    <h3 className="title">Page Not Found</h3>
                                    <p>The page you are looking for was moved, removed,renamed or might never
                                        existed.</p>
                                    <a href="/" className="btn">Go Back to Home</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer/>
        </>
    )
}
