import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./components/Home";
import FreeInternetApply from "./components/FreeInternetApply";
import FreeInternetQualify from "./components/FreeInternetQualify";
import TermsConditions from "./components/TermsConditions";
import Coverage from "./components/Coverage";
import HowItWorks from "./components/HowItWorks";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Partners from "./components/Partners";
import ContactUS from "./components/ContactUS";
import NoPage from "./components/NoPage";


/* Ref Site form Client = https://www.wrazzle.com/ */
function AcpWeb() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/contact-us" element={<ContactUS />} />
                <Route path="/free-internet-apply" element={<FreeInternetApply />} />
                <Route path="/free-internet-do-i-qualify" element={<FreeInternetQualify />} />
                <Route path="/terms-conditions" element={<TermsConditions />} />
                <Route path="/coverage" element={<Coverage />} />
                <Route path="/how-it-works" element={<HowItWorks />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/partners" element={<Partners />} />
                <Route path="/" element={<Home />} />
                <Route path="*" element={<NoPage />} />
            </Routes>
        </BrowserRouter>
    )
  }

export default AcpWeb;
