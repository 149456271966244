import React, {useState, useRef} from 'react';
import {useNavigate} from "react-router-dom"
import Header from "./Header";
import Footer from "./Footer";
import Breadcrumb from "./Breadcrumb";
import axios from 'axios';

export default function ContactUS() {
    var BreadCrumbTitle = "Contact Us";
    document.title = BreadCrumbTitle;

    const [name, setName] = useState('');
    const [lname, setLName] = useState('');
    const [phone, setPhone] = useState('');
    const [state, setState] = useState('');
    const [zipcode, setZipCode] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const formData = ({name, phone, email, subject, message})
    const [formResponse, setFormResponse] = useState('');

    const [ajaxResponseContent, setAjaxResponse] = useState('');

    const ajaxResponseHTML = useRef();
    const navigate = useNavigate();

    const handleSubmit = async () => {

        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
            "X-Requested-With": "*",
            "Access-Control-Allow-Methods": "OPTIONS,POST",
            "Access-Control-Allow-Credentials": true,
        };

        const data = {
            "host": "freewirelessoptions",
            "first_name": name,
            "last_name": lname,
            "email": email,
            "phone": phone,
            "address": '',
            "state": state,
            "zipcode": zipcode,
            "dob": "",
            "lead_id": document.getElementById('leadid_token').value,
            "ip_address": '',
            "remarks": message
        };
        console.log("Form DATA: " + JSON.stringify(data));

        const url = 'https://freewirelessoptions.com/process_api.php';
        //const url = 'http://localhost/process_api.php';

        const result = await axios.post(url, data, {
            headers: headers
        });

        //console.log(result.data.status);
        ajaxResponseHTML.current.classList.add('alert');

        if (result.data.status == 'fail') {
            ajaxResponseHTML.current.classList.remove('alert-success');
            ajaxResponseHTML.current.classList.add('alert-danger');
            setAjaxResponse(result.data.message);
        }
        if (result.data.status == 'success') {
            ajaxResponseHTML.current.classList.add('alert-success');
            ajaxResponseHTML.current.classList.remove('alert-danger');
            setAjaxResponse(result.data.message);
            const timeout = setTimeout(() => {
                navigate("/");
            }, 2000);
        }
    };

    (function () {
        var s = document.createElement('script');
        s.id = 'LeadiDscript_campaign';
        s.type = 'text/javascript';
        s.async = true;
        s.src = '//create.lidstatic.com/campaign/53acc0d5-01c0-c0b4-7fe8-e74a24bb9de4.js?snippet_version=2';
        var LeadiDscript = document.getElementById('LeadiDscript');
        LeadiDscript.parentNode.insertBefore(s, LeadiDscript);
    })();

    return (
        <>
            <Header/>
            <main>
                <Breadcrumb PageTitle={BreadCrumbTitle}/>

                <section className="contact-area pt-115 pb-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="contact-title">
                                    <h3 className="title">Get in Touch</h3>
                                </div>
                                <form onSubmit={handleSubmit} action="#" method="post" className="contact-form"
                                      name='contact-form' id='contact-form'>
                                    <input id="leadid_token" name="universal_leadid" type="hidden" value=""/>
                                    <div className="row">
                                        <div className="col-md-6 hide d-none">
                                            <div className="form-grp">
                                                <input type="text" id="name" autoComplete="off"
                                                       value={name} onChange={e => setName(e.target.value)}
                                                />
                                                <label htmlFor="name">First Name*</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 hide d-none">
                                            <div className="form-grp">
                                                <input type="text" id="lname" autoComplete="off"
                                                       value={lname} onChange={e => setLName(e.target.value)}
                                                />
                                                <label htmlFor="lname">Last Name*</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-grp">
                                                <input type="number" id="phone" autoComplete="off"
                                                       value={phone} onChange={e => setPhone(e.target.value)}
                                                />
                                                <label htmlFor="phone">Phone</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 hide d-none">
                                            <div className="form-grp">
                                                <input type="email" id="email" autoComplete="off"
                                                       value={email} onChange={e => setEmail(e.target.value)}
                                                />
                                                <label htmlFor="email">Email*</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 hide d-none">
                                            <div className="form-grp">
                                                <input type="text" id="state" autoComplete="off"
                                                       value={state} onChange={e => setState(e.target.value)}
                                                />
                                                <label htmlFor="state">State</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 hide d-none">
                                            <div className="form-grp">
                                                <input type="number" id="state" autoComplete="off"
                                                       value={zipcode} onChange={e => setZipCode(e.target.value)}
                                                />
                                                <label htmlFor="zipcode">Zip Code</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-grp hide d-none">
                                        <textarea name="message" id="message" autoComplete="off" required
                                                  value={message} onChange={e => setMessage(e.target.value)}></textarea>
                                        <label htmlFor="message">Messages</label>
                                    </div>

                                    <div className="col-lg-12 col-md-12 col-xs-12">
                                        <p>
                                            <label style={{fontSize:"12px", fontWeight:"normal", color: "#313"}}>
                                                <input type="hidden" id="leadid_tcpa_disclosure" />
                                                    [By clicking the "Submit" button below, I agree to
                                                    this website's <a href="/terms-conditions">terms of
                                                    service</a> and <a href="/privacy">privacy policy</a>,
                                                    and provide my express consent for this company and up to <a
                                                    href="/partners">three third party insurance companies or their
                                                    agents or partner companies</a>
                                                    to contact me at the email address, landline or wireless phone
                                                    numbers that I provide even if they are listed on any government
                                                    Do-Not-Call registries,
                                                    in order to send me marketing/promotional messages, including
                                                    insurance quotes related to the product or service I am inquiring
                                                    about.

                                                    I agree to receive communications via live, automated telephone
                                                    dialing system, prerecorded messages,
                                                    or text messages and acknowledge that consent is not a condition to
                                                    purchase goods or services.
                                                    Products and services offered on this page include Medicare
                                                    Supplement, Medicare Advantage and Prescription Drug Plans Consent
                                                    provided hereunder can be revoked by emailing
                                                    support@mediquotes.us”.

                                                    Participating agents represent Medicare Advantage [HMO, PPO, PFFS,
                                                    and PDP] organizations that are contracted with Medicare.
                                                    Enrollment depends on the plan’s contract renewal. Participating
                                                    agents do not offer every plan available in your area.

                                                    Any information we provide is limited to those plans offer in your
                                                    area. Please contact Medicare.gov or 1–800–MEDICARE to get
                                                    information on all of your options.
                                                    Enrollment in the described plan type may be limited to certain
                                                    times of the year unless you eligible for a Special Enrollment
                                                    Period.
                                                    Telephone company may impose additional charges on subscriber for
                                                    messages (required for SMS).This is a solicitation for insurance.
                                                    Your information will be provided to a licensed agent for future
                                                    contact.] </label>
                                        </p>
                                        <div className="clearfix"></div>
                                    </div>

                                    <div className="form-grp" ref={ajaxResponseHTML}>{ajaxResponseContent}</div>

                                    <button type="button" onClick={handleSubmit} className="btn">Submit</button>
                                </form>
                            </div>

                            <div className="col-lg-4">
                                <div className="contact-info-wrap">
                                    <h3 className="contact-info-title">Direct Contact</h3>
                                    <p>We are the broadband internet service provider at New</p>
                                    <ul className="contact-info-list">
                                        <li className="hide d-none">
                                            <i className="fal fa-phone"></i>
                                            <a href="tel:123">+1 872 670 780</a>
                                        </li>
                                        <li>
                                            <i className="fal fa-envelope"></i>
                                            <a href="mailto:info@freewirelessoptions.com">
                                                <span>info@freewirelessoptions.com</span>
                                            </a>
                                        </li>
                                        <li className="hide d-none">
                                            <i className="fal fa-map-marker-alt"></i>
                                            <span>3741 Hamilton Drive Huntingtown, <br/> MD 20639</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="contact-info-wrap">
                                    <h3 className="contact-info-title">Live Chat</h3>
                                    <p>We are the broadband internet service provider at New</p>
                                    <div className="live-chat">
                                        <div className="icon"><i className="flaticon-chat"></i></div>
                                        <a href="#" className="live-chat-link">Live Chat to Executive</a>
                                    </div>
                                </div>
                                <div className="contact-info-wrap">
                                    <h3 className="contact-info-title">Not interested to talk?</h3>
                                    <p>Please check out or best suggested <a href="#">Help Center & FAQ</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer/>
        </>
    )
}
