import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import Breadcrumb from "./Breadcrumb";

export default function FreeInternetQualify() {
    var BreadCrumbTitle = "Do I Qualify?";
    document.title = BreadCrumbTitle;
    return (
        <>
            <Header/>
            <main>
                <Breadcrumb PageTitle={BreadCrumbTitle}/>

                <section className="pricing-area pt-110 pb-10">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-10 col-md-11">
                                <div className="section-title text-center mb-55">
                                    <h2>Your household has new ways to qualify for the Affordable Connectivity Program
                                        such as receiving WIC benefits or having an income at or below 200% of the
                                        Federal Poverty Guidelines.
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="blog-details-area pt-120 pb-60">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="blog-details-wrap">
                                    <div className="standard-blog-content blog-details-content">
                                        <h2 className="title">Check List</h2>
                                        <ul className="about-list">
                                            <li style={{flex: "0 0 100%"}}>
                                                Qualifies for Affordable Connectivity Program benefits through
                                                participation in SNAP, Medicaid, Supplemental Security Income, Federal
                                                Public Housing Assistance, or Veterans and Survivors Pension Benefit.
                                            </li>
                                            <li style={{flex: "0 0 100%"}}>
                                                Participates in one of several Tribal specific programs: Bureau of
                                                Indian Affairs General Assistance, Tribal Head Start (only households
                                                meeting the relevant income qualifying standard), Tribal Temporary
                                                Assistance for Needy Families (Tribal TANF), Food Distribution Program
                                                on Indian Reservations
                                            </li>
                                            <li style={{flex: "0 0 100%"}}>
                                                Received a federal Pell Grant in the current award year
                                            </li>
                                            <li style={{flex: "0 0 100%"}}>
                                                Received approval for benefits under the free and reduced-price school
                                                lunch program or the school breakfast program, including through the
                                                USDA Community Eligibility Provision, in the 2019-2020 or 2020-2021
                                                school year
                                            </li>
                                            <li style={{flex: "0 0 100%"}}>
                                                Only one free monthly service discount and one device discount is
                                                allowed per household. Program rules acknowledge there may be more than
                                                one eligible household residing at the same address.
                                            </li>
                                        </ul>
                                        <p>
                                            Only one free monthly service discount and one device discount is allowed
                                            per household. Program rules acknowledge there may be more than one eligible
                                            household residing at the same address.
                                        </p>

                                        <h2 className="title pt-50">Qualify Through Your Child or Dependent</h2>
                                        <p>
                                            Any member of your household can make your household eligible. For example,
                                            if your child or dependent participates in the Free and Reduced-Price School
                                            Lunch Program, your household qualifies for the Free Internet Benefit.
                                        </p>

                                        <h2 className="title pt-50">If You Already Receive Lifeline Benefits</h2>
                                        <p>
                                            Lifeline is a program to help make communications services more affordable
                                            for low-income consumers. If you currently receive Lifeline benefits,
                                            Medicaid, you automatically qualify for the Affordable Connectivity Program,
                                            and you can receive both benefits at the same time. You can apply your Free
                                            Internet Benefit and your Lifeline benefit to the same or separate services.
                                        </p>

                                        <h2 className="title pt-50">If You Use SNAP, Medicaid, or Other Programs</h2>
                                        <p>You can receive the Free Internet Benefit if you (or someone in your
                                            household) participates in one of these federal assistance programs:
                                            <br/>
                                            Tribal Specific Programs:
                                        </p>
                                        <ul className="about-list">
                                            <li style={{flex: "0 0 100%"}}>
                                                Bureau of Indian Affairs General Assistance,
                                            </li>
                                            <li style={{flex: "0 0 100%"}}>
                                                Tribal Head Start (only households meeting the relevant income
                                                qualifying standard),
                                            </li>
                                            <li style={{flex: "0 0 100%"}}>
                                                Tribal Temporary Assistance for Needy Families (Tribal TANF)
                                            </li>
                                            <li style={{flex: "0 0 100%"}}>
                                                Food Distribution Program on Indian Reservations
                                            </li>
                                            <li style={{flex: "0 0 100%"}}>
                                                Supplemental Nutrition Assistance Program (SNAP), formerly known as Food
                                                Stamps
                                            </li>
                                            <li style={{flex: "0 0 100%"}}>
                                                Medicaid
                                            </li>
                                            <li style={{flex: "0 0 100%"}}>
                                                Supplemental Security Income (SSI)
                                            </li>
                                            <li style={{flex: "0 0 100%"}}>
                                                Federal Public Housing Assistance (FPHA)
                                            </li>
                                            <li style={{flex: "0 0 100%"}}>
                                                Veterans Pension and Survivors Benefit
                                            </li>
                                        </ul>
                                        <p>
                                            Free and Reduced-Price School Lunch Program or School Breakfast Program,
                                            including at US Department of Agriculture Community Eligibility Provision
                                            schools
                                            <br/>
                                            Received a Federal Pell Grant in the current award year
                                            <br/>
                                            You may need to show a card, letter, or official document as proof that you
                                            participate in one of these programs when you apply for the Free Internet
                                            Benefit.
                                        </p>

                                        <h2 className="title pt-50">If Your Household Income Is a Certain Amount</h2>
                                        <p>
                                            You can get the Affordable Connectivity Program if your income is 200% or
                                            less than the federal poverty guidelines. The guideline is based on your
                                            household size and state.
                                            <br/>
                                            You will have to show proof of income, like pay stubs or a tax return, when
                                            you apply for the Free Internet Program.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </main>
            <Footer/>
        </>
    )
}
