import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import Breadcrumb from "./Breadcrumb";

export default function HowItWorks() {
    var BreadCrumbTitle = "How It Works";
    document.title = BreadCrumbTitle;
    return (
        <>
            <Header/>
            <main>
                <Breadcrumb PageTitle={BreadCrumbTitle}/>


                <section className="services-area pt-120">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="faq-map-area">
                                    <div className="faq-map-title text-center">
                                        <h2>SWITCHING IS SIMPLE</h2>
                                        <p className="w-auto">We've made the process to switch from a different provider
                                            easy.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="services-area pt-2 pb-90">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="services-item">
                                    <div className="services-content">
                                        <h3 className="title">Choose Your Plan & Create Your Account</h3>
                                        <p>Pick the best plan that fits your needs! Choose how much Premium data you
                                            want and create your account from the comfort of your home!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="services-item">
                                    <div className="services-content">
                                        <h3 className="title">Bring Your Own Device</h3>
                                        <p>Do you want to keep your own device? Bring it on! Just make sure your device
                                            is unlocked and compatible.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="services-item">
                                    <div className="services-content">
                                        <h3 className="title">Keep Your Current Number</h3>
                                        <p>You can also bring your phone number. Simply select the option 'Transfer My
                                            Number' during the order process.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="services-item">
                                    <div className="services-content">
                                        <h3 className="title">Insert Your SIM Card & Activate</h3>
                                        <p>Simply insert your SIM card, restart your device and Live Life Without
                                            Limits!</p>
                                    </div>
                                </div>
                            </div>

                            <h2 className="text-center">Let the Savings Begin!</h2>
                        </div>
                    </div>
                </section>
            </main>
            <Footer/>
        </>
    )
}
