import React, {useState, useEffect, useRef} from 'react';

import Header from "./Header";
import Footer from "./Footer";
import {useNavigate} from "react-router-dom";
import axios from "axios";

export default function Home() {
    const [name, setName] = useState('');
    const [lname, setLName] = useState('');
    const [phone, setPhone] = useState('');
    const [state, setState] = useState('');
    const [zipcode, setZipCode] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const formData = ({name, phone, email, subject, message})
    const [formResponse, setFormResponse] = useState('');

    const [ajaxResponseContent, setAjaxResponse] = useState('');

    const ajaxResponseHTML = useRef();
    const navigate = useNavigate();

    const handleSubmit = async () => {

        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
            "X-Requested-With": "*",
            "Access-Control-Allow-Methods": "OPTIONS,POST",
            "Access-Control-Allow-Credentials": true,
        };

        const data = {
            "host": "freewirelessoptions",
            "first_name": name,
            "last_name": lname,
            "email": email,
            "phone": phone,
            "address": '',
            "state": state,
            "zipcode": zipcode,
            "dob": "",
            "lead_id": document.getElementById('leadid_token').value,
            "ip_address": '',
            "remarks": message
        };
        console.log("Form DATA: " + JSON.stringify(data));

        const url = 'https://freewirelessoptions.com/process_api.php';
        //const url = 'http://localhost/process_api.php';

        const result = await axios.post(url, data, {
            headers: headers
        });

        //console.log(result.data.status);
        ajaxResponseHTML.current.classList.add('alert');

        if (result.data.status == 'fail') {
            ajaxResponseHTML.current.classList.remove('alert-success');
            ajaxResponseHTML.current.classList.add('alert-danger');
            setAjaxResponse(result.data.message);
        }
        if (result.data.status == 'success') {
            ajaxResponseHTML.current.classList.add('alert-success');
            ajaxResponseHTML.current.classList.remove('alert-danger');
            setAjaxResponse(result.data.message);
            const timeout = setTimeout(() => {
                navigate("/");
            }, 2000);
        }
    };

    (function () {
        var s = document.createElement('script');
        s.id = 'LeadiDscript_campaign';
        s.type = 'text/javascript';
        s.async = true;
        s.src = '//create.lidstatic.com/campaign/53acc0d5-01c0-c0b4-7fe8-e74a24bb9de4.js?snippet_version=2';
        var LeadiDscript = document.getElementById('LeadiDscript');
        LeadiDscript.parentNode.insertBefore(s, LeadiDscript);
    })();

    return (
        <>
            <Header/>
            <main>
                <section className="slider-area">
                    <div className="slider-active">
                        <div className="single-slider slider-bg" style={{"minHeight":"auto","padding":"auto"}} data-background="template/img/slider/slider_bg01.jpg">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-7 col-md-9">
                                        <div className="banner-content">
                                            <h2 className="title" data-animation="fadeInUp" data-delay=".2s"
                                                data-duration="1.2s">WIRELESS NETWORK FOR EVERYONE</h2>
                                            <a href="/contact-us" className="btn" data-animation="fadeInUp"
                                               data-delay="1s" data-duration="1.2s">Contact Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pt-110 pb-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className="section-title mb-30">
                                            <h2 className="title">Do I Qualify?</h2>
                                        </div>
                                        <div className="about-content">
                                            <p>See if your household fits into any eligible categories.</p>
                                            <ul className="about-list">
                                                <li style={{flex: "0 0 100%"}}>
                                                    Your households have new ways to qualify for the Affordable
                                                    Connectivity
                                                    Program such as: receiving WIC benefits or having an income at or
                                                    below 200%
                                                    of the Federal Poverty Guidelines
                                                </li>

                                                <li style={{flex: "0 0 100%"}}>
                                                    Qualifies for Affordable Connectivity Program benefits through
                                                    participation
                                                    in SNAP, Medicaid, Supplemental Security Income, Federal Public
                                                    Housing
                                                    Assistance, or Veterans and Survivors Pension Benefit.
                                                </li>

                                                <li style={{flex: "0 0 100%"}}>
                                                    You can get the Affordable Connectivity Program if your income is
                                                    200% or
                                                    less than the federal poverty guidelines. The guideline is based on
                                                    your
                                                    household size and state.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-6">
                                <div className="contact-title">
                                    <h3 className="title">Get in Touch</h3>
                                </div>
                                <form onSubmit={handleSubmit} action="#" method="post" className="contact-form"
                                      name='contact-form' id='contact-form'>
                                    <input id="leadid_token" name="universal_leadid" type="hidden" value=""/>
                                    <div className="row">
                                        <div className="col-md-6 hide d-none">
                                            <div className="form-grp">
                                                <input type="text" id="name" autoComplete="off"
                                                       value={name} onChange={e => setName(e.target.value)}
                                                />
                                                <label htmlFor="name">First Name*</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 hide d-none">
                                            <div className="form-grp">
                                                <input type="text" id="lname" autoComplete="off"
                                                       value={lname} onChange={e => setLName(e.target.value)}
                                                />
                                                <label htmlFor="lname">Last Name*</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-grp">
                                                <input type="number" id="phone" autoComplete="off" maxLength="10"
                                                       value={phone} onChange={e => setPhone(e.target.value)}
                                                />
                                                <label htmlFor="phone">Phone</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 hide d-none">
                                            <div className="form-grp">
                                                <input type="email" id="email" autoComplete="off"
                                                       value={email} onChange={e => setEmail(e.target.value)}
                                                />
                                                <label htmlFor="email">Email*</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 hide d-none">
                                            <div className="form-grp">
                                                <input type="text" id="state" autoComplete="off"
                                                       value={state} onChange={e => setState(e.target.value)}
                                                />
                                                <label htmlFor="state">State</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 hide d-none">
                                            <div className="form-grp">
                                                <input type="number" id="state" autoComplete="off"
                                                       value={zipcode} onChange={e => setZipCode(e.target.value)}
                                                />
                                                <label htmlFor="zipcode">Zip Code</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-grp hide d-none">
                                        <textarea name="message" id="message" autoComplete="off" required
                                                  value={message} onChange={e => setMessage(e.target.value)}></textarea>
                                        <label htmlFor="message">Messages</label>
                                    </div>

                                    <div className="col-lg-12 col-md-12 col-xs-12">
                                        <p>
                                            <label style={{fontSize: "12px", fontWeight: "normal", color: "#313"}}>
                                                <input type="hidden" id="leadid_tcpa_disclosure"/>
                                                [By clicking the "Submit" button below, I agree to
                                                this website's <a href="/terms-conditions">terms of
                                                service</a> and <a href="/privacy-policy">privacy policy</a>,
                                                and provide my express consent for this company and up to <a
                                                href="/partners">three third party insurance companies or their
                                                agents or partner companies</a>
                                                to contact me at the email address, landline or wireless phone
                                                numbers that I provide even if they are listed on any government
                                                Do-Not-Call registries,
                                                in order to send me marketing/promotional messages, including
                                                insurance quotes related to the product or service I am inquiring
                                                about.

                                                I agree to receive communications via live, automated telephone
                                                dialing system, prerecorded messages,
                                                or text messages and acknowledge that consent is not a condition to
                                                purchase goods or services.
                                                Products and services offered on this page include Medicare
                                                Supplement, Medicare Advantage and Prescription Drug Plans Consent
                                                provided hereunder can be revoked by emailing
                                                support@mediquotes.us”.

                                                Participating agents represent Medicare Advantage [HMO, PPO, PFFS,
                                                and PDP] organizations that are contracted with Medicare.
                                                Enrollment depends on the plan’s contract renewal. Participating
                                                agents do not offer every plan available in your area.

                                                Any information we provide is limited to those plans offer in your
                                                area. Please contact Medicare.gov or 1–800–MEDICARE to get
                                                information on all of your options.
                                                Enrollment in the described plan type may be limited to certain
                                                times of the year unless you eligible for a Special Enrollment
                                                Period.
                                                Telephone company may impose additional charges on subscriber for
                                                messages (required for SMS).This is a solicitation for insurance.
                                                Your information will be provided to a licensed agent for future
                                                contact.] </label>
                                        </p>
                                        <div className="clearfix"></div>
                                    </div>

                                    <div id="ajaxResponse" className="form-grp"
                                         ref={ajaxResponseHTML}>{ajaxResponseContent}</div>

                                    <button type="button" id="submit_btn" onClick={handleSubmit}
                                            className="btn">Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="services-area services-bg" data-background="template/img/bg/services_bg.jpg">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-lg-7">
                                <div className="section-title white-title text-center mb-50">
                                    <h2 className="title">HOW IT WORKS</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center pt-10">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-9">
                                <div className="services-item service-item-two">
                                    <div className="services-icon">
                                        1
                                    </div>
                                    <div className="services-content">
                                        <h3 className="title">Check Coverage</h3>
                                        <p>Verify coverage in your area by entering the zip code</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-9">
                                <div className="services-item service-item-two">
                                    <div className="services-icon">
                                        2
                                    </div>
                                    <div className="services-content">
                                        <h3 className="title">Check Phone Compatibility</h3>
                                        <p>Verify your device using the IMEI</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-9">
                                <div className="services-item service-item-two">
                                    <div className="services-icon">
                                        3
                                    </div>
                                    <div className="services-content">
                                        <h3 className="title">Choose Your Plan</h3>
                                        <p>Select the plan that suits your needs</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pricing-area pt-110 pb-10">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-10 col-md-11">
                                <div className="section-title text-center mb-55">
                                    <h2 className="title">Applying Is Easy:</h2>
                                    <h2>Having issues applying? Contact our support team at 833-761-1767.</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="counter-area pb-65">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-sm-6">
                                <div className="counter-item">
                                    <h5>Begin the online application</h5>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="counter-item">
                                    <h5>Qualify through the National Verifier</h5>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="counter-item">
                                    <h5>Get your Tablet or FREE smartphone in the mail</h5>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="counter-item">
                                    <h5>Activate service by calling from your new smartphone</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            <Footer/>
        </>
    )
}
