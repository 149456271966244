import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import Breadcrumb from "./Breadcrumb";

export default function FreeInternetApply() {
    var BreadCrumbTitle = "Free Internet Apply";
    document.title = BreadCrumbTitle;
    return (
        <>
            <Header/>
            <main>
                <Breadcrumb PageTitle={BreadCrumbTitle}/>

                <section className="pricing-area pt-110 pb-10">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-10 col-md-11">
                                <div className="section-title text-center mb-55">
                                    <h2 className="title">Applying Is Easy:</h2>
                                    <h2>Having issues applying? Contact our support team at 800-380-0547.</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="counter-area pb-65">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-sm-6">
                                <div className="counter-item">
                                    <h5>Begin the online application</h5>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="counter-item">
                                    <h5>Qualify through the National Verifier</h5>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="counter-item">
                                    <h5>Get your Tablet or FREE smartphone in the mail</h5>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="counter-item">
                                    <h5>Activate service by calling from your new smartphone</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="cta-area cta-bg jarallax" data-background="template/img/bg/cta_bg.jpg">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-9 col-lg-10">
                                <div className="cta-content text-center">
                                    <div className="section-title white-title mb-35">
                                        <h2 className="title">Port your Current Phone Number and get an iPhone or
                                            newer on us.</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="about-area pt-120">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="about-img-wrap">
                                    <img src="template/img/images/about_img01.jpg" alt=""/>
                                    <img src="template/img/images/about_img02.jpg" className="main-img" alt=""/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="section-title mb-30">
                                    <h2 className="title">Do I Qualify?</h2>
                                </div>
                                <div className="about-content">
                                    <p>See if your household fits into any eligible categories.</p>
                                    <ul className="about-list">
                                        <li style={{flex: "0 0 100%"}}>
                                            Your households have new ways to qualify for the Affordable Connectivity
                                            Program such as: receiving WIC benefits or having an income at or below 200%
                                            of the Federal Poverty Guidelines
                                        </li>

                                        <li style={{flex: "0 0 100%"}}>
                                            Qualifies for Affordable Connectivity Program benefits through participation
                                            in SNAP, Medicaid, Supplemental Security Income, Federal Public Housing
                                            Assistance, or Veterans and Survivors Pension Benefit.
                                        </li>

                                        <li style={{flex: "0 0 100%"}}>
                                            You can get the Affordable Connectivity Program if your income is 200% or
                                            less than the federal poverty guidelines. The guideline is based on your
                                            household size and state.
                                        </li>
                                    </ul>
                                    <a href="/contact-us" className="btn transparent-btn">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </main>
            <Footer/>
        </>
    )
}
