import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import Breadcrumb from "./Breadcrumb";

export default function PrivacyPolicy() {
    var BreadCrumbTitle = "Privacy Policy";
    document.title = BreadCrumbTitle;
    return (
        <>
            <Header/>
            <main>
                <Breadcrumb PageTitle={BreadCrumbTitle}/>


                <section className="services-area pt-120">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="faq-map-area">
                                    <div className="faq-map-title text-center">
                                        <h3 className="w-auto">We value your privacy and are committed to protecting any
                                            personal information you provide us while using our free wireless
                                            network.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="services-area pt-2 pb-90">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-4 col-md-6">
                                <div className="services-item">
                                    <div className="services-content">
                                        <h3 className="title">Information Collection:</h3>
                                        <p>We may collect personal information such as your name, email address, and
                                            device information when you use our wireless network. We may also collect
                                            non-personal information such as your device's IP address and location data.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-4 col-md-6">
                                <div className="services-item">
                                    <div className="services-content">
                                        <h3 className="title">Information Use:</h3>
                                        <p>We may use your personal information to communicate with you, improve our
                                            services, and personalize your experience. We may also use non-personal
                                            information for statistical analysis and to improve network performance.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-4 col-md-6">
                                <div className="services-item">
                                    <div className="services-content">
                                        <h3 className="title">Information Disclosure:</h3>
                                        <p>We will not disclose your personal information to third parties without your
                                            consent, except as required by law or to protect our legal rights. We may
                                            share non-personal information with third-party service providers to improve
                                            network performance.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-4 col-md-6">
                                <div className="services-item">
                                    <div className="services-content">
                                        <h3 className="title">Data Security:</h3>
                                        <p>We take reasonable measures to protect your personal information from
                                            unauthorized access, use, or disclosure.
                                        </p>
                                        <h3 className="title">Changes to this Privacy Policy:</h3>
                                        <p>We reserve the right to modify this Privacy Policy at any time. We will
                                            notify you of any material changes via our website or email.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <h2 className="text-center">Let the Savings Begin!</h2>
                        </div>
                    </div>
                </section>
            </main>
            <Footer/>
        </>
    )
}
