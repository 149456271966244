import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import Breadcrumb from "./Breadcrumb";

export default function Coverage() {
    var BreadCrumbTitle = "Coverage";
    document.title = BreadCrumbTitle;
    return (
        <>
            <Header/>
            <main>
                <Breadcrumb PageTitle={BreadCrumbTitle}/>

                <section className="pricing-area pt-110 pb-10">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-10 col-md-11">
                                <div className="section-title text-center mb-55">
                                    <h2 className="title">Amazing 5G & 4G LTE Coverage</h2>
                                    <h2>Coast to Coast!</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="subscribe-two-area subscribe-two-bg" data-background="template/img/bg/subscribe_bg02.jpg">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-7 col-lg-6 order-0 order-lg-2">
                                <div className="subscribe-img wow fadeInRight" data-wow-delay=".2s">
                                    <img src="template/img/images/subscribe_img02.png" alt=""/>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6">
                                <div className="section-title white-title mb-30">
                                    <h2 className="title">Do you have an Apple iOS or Android device with 5G capability?</h2>
                                </div>
                                <div className="subscribe-content">
                                    <p>Get ready for unlimited possibilities with Wrazzle! Our 5G Network is ready for you to experience</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer/>
        </>
    )
}
